@import 'src/styles/functions';
@import 'src/styles/mixins';

.wrapper {
  min-width: calc(100% - 32px);
  max-width: calc(100% - 32px);
  margin: 0 auto;

  @media (min-width: $desktop-size) {
    width: 100%;
    min-width: 1160px;
    max-width: 1160px;
  }
}
