.container {
  // stylelint-disable-next-line
  :global(.Toastify__toast-container) {
    // stylelint-disable-next-line
    :global(.Toastify__toast) {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  // stylelint-disable-next-line
  :global(.Toastify__toast) {
    margin: 16px 24px;
    background: white;
    border-radius: 4px;

    @media (min-width: 992px) {
      margin: 0;
      margin-bottom: 16px;
      border-top: 4px solid #d66;
      border-radius: 4px;
      box-shadow: 0 4px 16px rgb(0 0 0 / 8%);

      &.--info {
        border-top: 4px solid #ffeec7;
      }

      &.--success {
        border-top: 4px solid #53a770;
      }
    }
  }

  // stylelint-disable-next-line
  :global(.Toastify__close-button > svg) {
    color: #a3a3a3;
  }

  // stylelint-disable-next-line
  :global(.Toastify__close-button) {
    opacity: 1;

    svg {
      transition: color 0.3s ease;

      &:hover {
        color: #343434;
      }
    }
  }

  // stylelint-disable-next-line
  :global(.Toastify__progress-bar--default) {
    background: #1ca8e3;
  }
}
