@import 'src/styles/functions';
@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  overflow: hidden;
  background: color(mainwhite);
  transition: transform 0.3s $base-transition;
  transform: translateX(100%);

  @include use-spacings(padding-top, xl);
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.show {
  transform: translateX(0);
}

.close-icon {
  position: absolute;
  top: 25px;
  right: 25px;
  min-width: 24px;
  min-height: 24px;

  @include flex(center);

  &:hover {
    cursor: pointer;
  }

  svg {
    width: 24px;
    height: 24px;
  }
}

.hint {
  @include use-spacings(margin-top, m);
  @include h5-text();
}

.search {
  @include use-spacings(margin-top, l);
}

.list {
  @include use-spacings(margin-top, m);

  display: block;
  flex: 1 1 auto;
  height: 0;
  overflow-y: auto;

  @include use-spacings(margin-bottom, m);
}

.option {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;

  @include big-body-text();

  &:hover {
    color: color(main);
    cursor: pointer;
  }
}

.option-active {
  color: color(main);
  pointer-events: none;
}

.empty {
  @include small-light-text();

  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}

@media (min-width: $mobile-size) {
  .list {
    display: flex;
    flex-flow: row wrap;
  }

  .option {
    width: 50%;
  }

  .search {
    width: 738px;
  }
}

@media (min-width: $tablet-size) {
  .wrapper {
    bottom: 18%;
    box-shadow: 0 18px 65px rgba(0, 0, 0, 0.07), 0 4.02054px 6.37957px rgba(0, 0, 0, 0.0287811), 0 1.19702px 0 rgba(0, 0, 0, 0.0082118);
    transition: transform 0.3s $base-transition;
    transform: translateY(-100%);
  }

  .show {
    transform: translateY(0);
  }

  .option {
    width: calc(100% / 3);
  }

  .list {
    @include use-spacings(margin-bottom, l);
  }

  .search {
    width: 592px;
  }
}
