@import 'variables';
@import 'functions';
@import 'mixins';
@import 'helpers';

html,
body,
#__next {
  height: 100%;

  @include body-text();

  font-weight: 500;
  }

html {
  font-family: var(--font-unineue);
  overflow-x: hidden;
  scroll-behavior: smooth;
}

a {
  color: inherit;
}

[href] {
  text-decoration: none;
}

html[data-popup-open='true'] {
  overflow: hidden;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  &::before,
  ::after {
    box-sizing: border-box;
  }
}

*:focus:not([data-focus-visible-added]) {
  outline: none;
}

a,
button {
  transition: $hoverTansition;
}

li:not(.on-style-type) {
  list-style-type: none;
}

button {
  border: none;
}

img {
  max-width: 100%;
}

h1 {
  @include h1-text();
}

h2 {
  @include h2-text();
}

h3 {
  @include h3-text();
}

h4 {
  @include h4-text();
}

h5 {
  @include h5-text();
}

svg {
  width: 40px;
  height: 40px;
}

.lazyload-wrapper {
  width: 100%;
}

@media (max-width: $min-screen-size) {
  body {
    min-width: $min-screen-size;
    max-width: $min-screen-size;
  }
}

.main-link {
  @include main-link();
}

.disabled-pointer-events {
  pointer-events: none;
  opacity: 0.9;
}

[role='input-wrap'] {
  &:not(:first-child) {
    @include use-spacings(margin-top, l);
  }
}

[role='inputs-wrap-2'] {
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    @include use-spacings(margin-top, l);
  }

  @media (min-width: $mobile-size) {
    @include flex-columns();

    [role='input-wrap'] {
      margin-top: 0;
    }
  }
}

.form-section-title {
  @include use-spacings(margin-top, l);
  @include use-spacings(margin-bottom, xs);
}

[role='button']:hover {
  cursor: pointer;
}

.overflow-hidden {
  overflow: hidden;
}

.visually-hidden {
  display: none;
}

.light-text {
  letter-spacing: 0.03em;
  opacity: 0.5;

  @include extra-small-text();
}
