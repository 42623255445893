@import 'src/styles/variables';
@import 'src/styles/functions';
@import 'src/styles/mixins';

.whats-app {
  position: fixed;
  right: 10px;
  bottom: 66px;
  z-index: 10;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 64px;
  transition: transform 0.3s ease-in-out;
  transform: scale(0.66);
  transform-origin: bottom right;

  @media (min-width: $mobile-size) {
    right: 20px;
    bottom: 20px;
    transform: scale(1);
  }
}

.logo {
  width: unset;
  height: unset;
  margin-top: -6px;
  transition: transform 0.3s ease-in-out;
}

.chat-app {
  display: flex;
  align-items: center;
  align-self: stretch;
  color: white;
  background: #30b944;
  border-radius: 32px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  @include big-bold-text();
  @include use-spacings(padding, 0, 'm');
}

.button-visible {
  opacity: 1;
}

.animateLogo {
  transform: translateX(180px);
}

.animate-button {
  opacity: 1;
}
