@import 'mixins';
@import 'variables';

$sides: (top, bottom, left, right);

@each $type in $spacings-types {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$type} {
      @include use-spacings(margin-#{$side}, $type);
    }

    .p#{str-slice($side, 0, 1)}-#{$type} {
      @include use-spacings(padding-#{$side}, $type);
    }

    // only screen
    .m#{str-slice($side, 0, 1)}-#{$type}-mob {
      @media (max-width: $mobile-size - 1) {
        @include use-spacings(margin-#{$side}, $type);
      }
    }

    .p#{str-slice($side, 0, 1)}-#{$type}-mob {
      @media (max-width: $mobile-size - 1) {
        @include use-spacings(padding-#{$side}, $type);
      }
    }
  }
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}
