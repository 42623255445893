@import 'src/styles/functions';
@import 'src/styles/variables';
@import 'src/styles/mixins';

.input-wrap {
  position: relative;
}

.simple-input-wrap {
  margin-bottom: 20px;
}

.input {
  width: 100%;
  height: 64px;
  padding: 20px 16px 0 16px;
  background: color(border);
  border: 1px solid color(border);
  border-radius: $base-border-radius;
  outline: none;

  &:not([disabled]) {
    &:hover {
      border-color: color(black50);
    }

    &:focus {
      background: #fff;
      border-color: color(black50);
    }
  }

  &:disabled {
    opacity: 0.5;
  }

  &[data-dropdown-placement="top"] {
    &:focus {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &[data-dropdown-placement="bottom"] {
    &:focus {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.input.simple-input {
  height: 40px;
  padding: 0 16px;
  margin-top: 10px;

  @media screen and (min-width: $mobile-size) {
    height: 64px;
  }
}

.label {
  position: absolute;
  top: calc(50% - 14px);
  left: 16px;
  font-size: 20px;
  line-height: 28px;
  pointer-events: none;
  opacity: 0.5;
  transition: $base-transition;
}

.simpleLabel {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  pointer-events: none;
  opacity: 0.5;
}

.input:focus + .label,
.has-value + .label {
  top: 4px;
  font-size: 16px;
  line-height: 24px;
}

.input-error:not([disabled]) {
  border: 1px solid color(error);
}

.input:disabled + .label {
  color: color(black50);
}

.help-text,
.error {
  position: absolute;

  @include small-text();
}

.help-text {
  @include light-text();
}

.error {
  color: color(error);
}

.adornment-wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  width: 60px;
}

@media (min-width: $mobile-size) {
  .help-text,
  .error {
    margin-top: 4px;
  }
}
