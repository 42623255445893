@import 'src/styles/functions';
@import 'src/styles/mixins';
@import 'src/styles/variables';

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background: rgb(0 0 0 / 0);
  transition: 0.2s ease-in;

  &.enter,
  &.entered {
    background: rgb(0 0 0 / 0.85);
    transition: 0.2s ease-out;
  }

  &.exit,
  &.exited {
    background: rgb(0 0 0 / 0);
  }
}

.content-wrap {
  position: absolute;
  bottom: 0;
  display: flex;
  max-height: 90%;
  overflow-y: auto;
  background: #fff;
  border-radius: $base-border-radius $base-border-radius 0 0;
  box-shadow: $card-shadow;
  transition: 0.2s ease-in;

  @include use-spacings(padding, m);
  @include use-spacings(left, s);
  @include use-spacings(right, s);

  &.enter,
  &.entered {
    transition: 0.2s ease-out;
  }
}

.content {
  position: relative;
  width: 100%;
  height: 100%;
}

.close {
  position: absolute;
  top: -5px;
  right: 0;
  display: flex;

  > svg {
    width: 40px;
    height: 40px;
  }

  &:hover {
    cursor: pointer;
  }
}

.header {
  margin-right: 50px;
}

.large-size {
  min-width: auto;
  max-width: none;
  padding: 20px;
}

.large-size.content-wrap {
  border-radius: 20px;
}

@media (max-width: $mobile-size - 1) {
  .content-wrap {
    bottom: -90%;

    &.enter,
    &.entered {
      bottom: 70px;
    }

    &.exit,
    &.exited {
      bottom: -90%;
    }
  }
}

@media (min-width: $mobile-size) {
  .overlay {
    @include flex(center);
  }

  .content-wrap {
    position: relative;
    right: initial;
    left: initial;
    border-radius: $base-border-radius;
    opacity: 0;
    transition: 0.2s ease-out;

    &.enter,
    &.entered {
      opacity: 1;
    }

    &.exit,
    &.exited {
      opacity: 0;
    }
  }

  .medium-size {
    min-width: 736px;
    max-width: 736px;
  }
}

@media (min-width: $tablet-size) {
  .medium-size {
    min-width: 770px;
    max-width: 770px;
  }

  .large-size {
    min-width: 1160px;
    max-width: 1160px;
    padding: 50px 60px;
  }

  .large-size.content-wrap {
    border-radius: 40px;
  }
}
